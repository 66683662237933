import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import fetchJsonp from "../../util/fetchJsonp";
import { ReactComponent as SpinnerSvg } from "../../images/spinner.svg";
import { formTitle, meta } from "../../styles/typography";
import TickSvg from "../../images/tick.svg";
import { transparentize } from "polished";
import { colours } from "../../styles/colours";

const inputHeightMobile = "48px";
const inputHeightTablet = "56px";
const inputHeight1200 = inputHeightTablet;
const inputHeight1800 = inputHeightTablet;

const inputMarginBottomMobile = "24px";
const inputMarginBottomTablet = inputMarginBottomMobile;
const inputMarginBottom1200 = "40px";
const inputMarginBottom1800 = "40px";

const Title = styled.h2`
	${formTitle};

	max-width: 554px;
	margin-bottom: 16px;

	@media screen and (min-width: 768px) {
		margin-bottom: 18px;
	}

	@media screen and (min-width: 1200px) {
		margin-bottom: 32px;
	}

	@media screen and (min-width: 1800px) {
		margin-bottom: 56px;
	}
`;
const FormWrapper = styled.div`
	width: 100%;
`;
const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	@media screen and (min-width: 1080px) {
		width: auto;
	}
`;
const InputContainer = styled.div`
	position: relative;
	flex: 1;
	width: 100%;
	margin-bottom: 0;
	@media screen and (min-width: 768px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: stretch;
		align-items: flex-end;
		max-width: 480px;
	}
	@media screen and (min-width: 1200px) {
		max-width: 560px;
		align-items: center;
	}
	@media screen and (min-width: 1800px) {
		max-width: 560px;
		align-items: flex-end;
	}
`;
const FieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	justify-content: space-evenly;
	width: 100%;
	margin-bottom: ${inputMarginBottomMobile};

	@media screen and (min-width: 768px) {
		margin-bottom: 0;
		margin-bottom: ${inputMarginBottomTablet};

		&.field-container {
			&--email {
				width: 100%;
			}
		}
	}

	@media screen and (min-width: 1200px) {
		margin-bottom: ${inputMarginBottom1200};
		width: 100%;

		&.field-container {
			margin-bottom: ${inputMarginBottom1200};
			&--email {
				flex-grow: 1;
			}
		}
	}

	@media screen and (min-width: 1800px) {
		margin-bottom: ${inputMarginBottom1800};

		&.field-container {
		}
	}
`;

const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	width: 100%;
	text-align: left;

	@media screen and (min-width: 1200px) {
		width: auto;
		margin-bottom: 0;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
`;
const Input = styled.input`
	${meta};
	width: 100%;
	letter-spacing: 0.04em;
	appearance: none;
	background: white;
	border: none;
	padding: 0 15px;
	color: white;
	box-sizing: border-box;
	background: none;
	border: 1px solid white;
	height: ${inputHeightMobile};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 0;

	&:focus {
		outline: none;
	}

	@media screen and (min-width: 768px) {
		padding: 0 23px;
		height: 100%;
		width: 100%;
		height: ${inputHeightTablet};
	}

	@media screen and (min-width: 1200px) {
		height: ${inputHeight1200};
	}

	@media screen and (min-width: 1800px) {
		height: ${inputHeight1800};
	}
`;
const CheckboxInput = styled.input`
	display: none;

	&:checked + label {
		&::before {
			background: white;
		}
		&::after {
			opacity: 1;
		}
	}
`;
const Label = styled.label`
	${meta};
	position: static;
	opacity: 1;
	line-height: 1.2;
	margin-bottom: 10px;
	display: inline-block;
	height: 17px;
`;
const CheckboxLabel = styled.label`
	${meta};
	text-align: center;
	color: white;
	transition: opacity 0.2s;
	cursor: pointer;
	box-sizing: border-box;
	background: none;
	border-left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	span {
		padding-left: 16px;
	}

	&:first-of-type {
		margin-bottom: 16px;
	}

	&::before {
		content: "";
		background: none;
		border: 1px solid white;
		padding: 14px;
		display: inline-block;
		transition: background-color 0.2s ease;
	}

	&::after {
		content: "";
		display: inline-block;
		width: 10px;
		height: 10px;
		position: absolute;
		width: 28px;
		height: 28px;
		top: 1px;
		left: 1px;
		opacity: 0;
		transition: opacity 0.2s ease;
		background-image: url(${TickSvg});
		background-position: center center;
		background-repeat: no-repeat;
	}

	&:hover,
	&:focus {
		&::before {
			background: ${transparentize(0.2, "white")};
		}
	}

	&:focus {
		outline: none;
	}

	@media screen and (min-width: 768px) {
		padding-right: 20px;
		margin-right: 28px;

		&:first-of-type {
			margin-bottom: 16px;
		}
	}

	@media screen and (min-width: 1200px) {
		width: auto;
		margin-right: 42px;

		&:first-of-type {
			margin-bottom: 0;
		}
	}
`;
const Submit = styled.button`
	${meta};
	border: none;
	padding: 0 47px;
	display: inline-block;
	font-weight: bold;
	background: none;
	height: ${inputHeightMobile};
	flex: 0;
	background: white;
	color: #001428;
	cursor: hand;
	cursor: pointer;
	transition: background-color 0.2s ease;
	margin: 0;
	width: 100%;

	&:hover {
		background: #a9c4e7;
	}

	&:focus {
		outline: none;
		background: #a9c4e7;
	}

	span {
		position: relative;
		top: -1px;
	}

	@media screen and (min-width: 768px) {
		height: ${inputHeightTablet};
		flex-grow: 1;
	}

	@media screen and (min-width: 1200px) {
		height: ${inputHeight1200};
		margin-right: 0;
		padding: 0 65px;
		flex-grow: 0;
	}

	@media screen and (min-width: 1800px) {
		height: ${inputHeight1800};
		width: auto;
		padding: 0 65px;
	}
`;
const Terms = styled.div`
	${meta};
	color: ${transparentize(0.6, "white")};
	margin-top: ${inputMarginBottomMobile};
	width: 100%;

	@media screen and (min-width: 768px) {
		margin-top: ${inputMarginBottomTablet};
	}

	@media screen and (min-width: 1200px) {
		margin-top: ${inputMarginBottom1200};
	}

	@media screen and (min-width: 1800px) {
		margin-top: ${inputMarginBottom1800};
	}
	a {
		text-decoration: underline;
		transition: color 0.2s ease;
		&:visited {
			color: ${transparentize(0.6, "white")};
		}

		&:hover {
			color: ${colours.highlight};
		}
	}
`;
const Message = styled.div`
	height: ${(props) => props.inputContainerElHeight};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;
const MessageText = styled.div`
	${meta};
	text-align: center;
	font-weight: 500;
	line-height: 1.2;
`;
const ErrorText = styled.div`
	${meta};
	text-align: center;
	font-weight: 500;
	margin-bottom: 16px;
	width: 100%;
`;
const Loading = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const Spinner = styled.div`
	border-radius: 50%;
	animation: 1.2s linear 0s infinite running spin;

	svg {
		fill: white;
		stroke: white;
		width: 30px;
		height: 30px;
	}

	@keyframes spin {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@media screen and (min-width: 1200px) {
		svg {
			width: 60px;
			height: 60px;
		}
	}
`;

const Newsletter = () => {
	const [focused, setFocused] = useState(false);
	const [hasContent, setHasContent] = useState(false);
	const [success, setSuccess] = useState(false);
	const [invalid, setInvalid] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [languageNotSelected, setLanguageNotSelected] = useState(false);

	const inputContainerEl = useRef(null);
	const inputContainerElHeight = useRef(0);

	useEffect(() => {
		inputContainerElHeight.current =
			inputContainerEl.current.clientHeight + "px";

		window.addEventListener("resize", updateFormHeight);

		return () => {
			window.removeEventListener("resize", updateFormHeight);
		};
	}, []);

	useEffect(() => {
		updateFormHeight();
	}, [focused]);

	const updateFormHeight = () => {
		if (inputContainerEl.current !== null) {
			inputContainerElHeight.current =
				inputContainerEl.current.clientHeight + "px";
		}
	};

	const submitForm = (e) => {
		if (!loading) {
			e.preventDefault();
			setInvalid(false);
			setError(false);
			setLanguageNotSelected(false);
			setLoading(true);

			// check that at least one language is selected
			const numLanguagesSelected = e.currentTarget.querySelectorAll(
				"input[type=checkbox]:checked"
			).length;
			if (numLanguagesSelected === 0) {
				setLanguageNotSelected(true);
				setLoading(false);
			} else {
				// submit
				let data = new FormData(e.currentTarget);
				let action = e.currentTarget.getAttribute("action");
				let params = new URLSearchParams([...data]);
				fetchJsonp(`${action}?${params.toString()}`, {
					jsonpCallback: "c",
				})
					.then((response) => response.json())
					.then(
						(response) => {
							const { msg, result } = response;
							if (
								result === "success" ||
								msg.includes("is already subscribed")
							) {
								setSuccess(true);
							} else if (
								msg.includes("Please enter a different email address")
							) {
								setInvalid(true);
							} else {
								setError(true);
							}
							setLoading(false);
							// track({ event: "SubscribeFormSubmitted" });
						},
						(error) => {
							setError(true);
							setLoading(false);
						}
					);
			}
		}
	};

	return (
		<FormWrapper>
			<Title>
				SIGN UP FOR THE <strong>LATEST NEWS</strong> AND{" "}
				<strong>INSIGHTS</strong>
			</Title>
			<Form
				action="https://gladeye.us4.list-manage.com/subscribe/post-json"
				method="POST"
				onSubmit={submitForm}>
				<input type="hidden" name="u" value="f7204e697ebd16299ab45914d" />
				<input type="hidden" name="id" value="2d90c08c90" />
				<input type="hidden" name="TYPE" value="Company" />
				{success ? (
					<Message inputContainerElHeight={inputContainerElHeight.current}>
						<MessageText>
							Please check your email to confirm your subscription.
						</MessageText>
					</Message>
				) : loading ? (
					<Message inputContainerElHeight={inputContainerElHeight.current}>
						<Loading>
							<Spinner>
								<SpinnerSvg />
							</Spinner>
						</Loading>
					</Message>
				) : (
					<InputContainer ref={inputContainerEl}>
						{invalid ? (
							<ErrorText>Please enter a valid email address.</ErrorText>
						) : error ? (
							<ErrorText>
								There was an issue submitting your details. Please try again.
							</ErrorText>
						) : languageNotSelected ? (
							<ErrorText>Please select at least one language.</ErrorText>
						) : null}
						<FieldContainer className="field-container--email">
							<Label
								htmlFor={"email"}
								minimise={focused || hasContent}
								className="field-label">
								Email
							</Label>
							<Input
								id={"email"}
								type={"email"}
								name={"MERGE0"}
								required
								onChange={(e) => {
									if (e.currentTarget.value) {
										setHasContent(true);
									} else {
										setHasContent(false);
									}
								}}
								onFocus={() => {
									setFocused(true);
								}}
								onBlur={() => {
									setFocused(false);
								}}
							/>
						</FieldContainer>

						<FieldContainer>
							<Label>Select languages</Label>

							<OptionsContainer>
								<CheckboxInput
									id={"group_1"}
									type={"checkbox"}
									name={"group[71354][1]"}
									value={"1"}
								/>
								<CheckboxLabel htmlFor={"group_1"}>
									<span>FRENCH</span>
								</CheckboxLabel>

								<CheckboxInput
									id={"group_2"}
									type={"checkbox"}
									name={"group[71354][2]"}
									value={"1"}
								/>
								<CheckboxLabel htmlFor={"group_2"}>
									<span>ENGLISH</span>
								</CheckboxLabel>
							</OptionsContainer>
						</FieldContainer>

						<Submit>
							<span>SUBMIT</span>
						</Submit>

						<Terms>
							<p>
								Your e-mail address is used to send you newsletters and
								marketing information on L’Atelier. You can unsubscribe at any
								time by using the unsubscribe link in our emails. More
								information about the management of your personal data and your
								rights can be found in our{" "}
								<a
									href="https://atelier.net/data-protection-notice"
									target="_blank"
									rel="noreferrer">
									Data Protection Notice
								</a>
								.
							</p>
						</Terms>
					</InputContainer>
				)}
			</Form>
		</FormWrapper>
	);
};

export default Newsletter;
